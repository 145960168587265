export default [
  {
    index: 0,
    key: "amazonia_limit",
    labelKey: "amazonia_limit",
    color: "#B14018",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:amazonia_limit"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/raisg_limit.zip'
  },
  {
    index: 1,
    key: "biome",
    labelKey: "biome",
    color: "#a86232",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:biome"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/biomes.zip'
  },
  {
    index: 2,
    key: "country",
    labelKey: "country",
    color: "#B024E0",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/country.zip'
  },
  {
    index: 3,
    key: "political_level_1",
    labelKey: "political_level_1",
    color: "#3A3D3A",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:political_level_1"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/state.zip'
  },
  {
    index: 4,
    key: "political_level_2",
    labelKey: "political_level_2",
    color: "#F4B615",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:political_level_2"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/city.zip'
  },
  {
    index: 5,
    key: "watershed",
    labelKey: "watershed",
    color: "#16DFF2",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:watershed"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/drainage_basin.zip'
  },
  {
    index: 6,
    key: "indigenous_territories",
    labelKey: "indigenous_territories",
    color: "#FDAE61",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:indigenous_territories"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/indigenous_territories.zip'
  },
  {
    index: 7,
    key: "state_protected_areas",
    labelKey: "state_protected_areas",
    color: "#1A7836",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:state_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/state_protected_areas.zip'
  },
  {
    index: 8,
    key: "federal_protected_areas",
    labelKey: "federal_protected_areas",
    color: "#C51B7D",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-amazonia-col5:federal_protected_areas"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Adaptada en base a datos Oficiales' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/amazonia/collection_5/download/layers/federal_protected_areas.zip'
  }
];
